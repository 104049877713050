import { render, staticRenderFns } from "./ScenkonstDigitalStage.vue?vue&type=template&id=d5e439ee&"
import script from "./ScenkonstDigitalStage.vue?vue&type=script&lang=ts&"
export * from "./ScenkonstDigitalStage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlockDigitalScene: require('/tmp/build_bea293ab/src/components/block/DigitalScene.vue').default})
